<template>
  <div>
    <base-material-card
      title="项目专班"
      icon="fa4 fa-bank"
    >
      <div class="pa-6">
        <v-chip
          v-for="user in UserGroup"
          :key="user.id"
          class="ma-2"
          color="indigo"
          outlined
          @click="getUserInfo(user.id)"
        >
          <v-avatar left>
            <img
              :src="user.avatar ? user.avatar : userPhoto"
              :alt="user.nickname"
            >
          </v-avatar>
          {{ user.nickname }}
        </v-chip>
      </div>
    </base-material-card>
    <v-dialog
      v-model="userInfoModel"
      max-width="500"
    >
      <v-card max-width="500">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ userInfo.nickname }}
            </v-list-item-title>
            <v-list-item-subtitle>
              部门信息
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-chip
            class="ma-2"
            color="indigo"
            text-color="white"
          >
            <v-avatar left>
              <v-icon>
                fa4 fa-phone
              </v-icon>
            </v-avatar>
            {{ userInfo.mobile }}
          </v-chip>
          <v-chip
            v-if="userInfo.office"
            class="ma-2"
            color="indigo"
            text-color="white"
          >
            <v-avatar left>
              <v-icon>
                fa4 fa-user
              </v-icon>
            </v-avatar>
            {{ userInfo.office }}
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import userPhoto from '../../../assets/user_photo.jpg'
  export default {
    props: {
      formId: {
        type: String,
        default: null,
      },
      reportId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        userInfoModel: false,
        userInfo: {},
        id: [],
        UserGroup: [],
        userPhoto: userPhoto,
      }
    },
    created () {
      this.getUserGroup()
    },
    methods: {
      getUserGroup () {
        this.$axios.get(`/reports/${this.reportId}/users`)
          .then(res => {
            const resData = res.data.data
            const groupUser = resData.filter(user => {
              return user.roles.includes('ROLE_ZHUANBAN')
            })
            this.UserGroup = groupUser.sort(this.compare('weight'))
          })
      },
      compare (property) {
        return function (a, b) {
          return a[property] - b[property]
        }
      },
      getUserInfo (id) {
        this.userInfoModel = true
        this.$axios.get('/users/' + id + '/show')
          .then(res => {
            this.userInfo = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>
<style scoped>
.adas{
  display: inline;
}
</style>
